@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("./lib/css/all.min.css");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
    @apply font-bold;
  }

  h2 {
    @apply text-[1.8rem] md:text-4xl;
    @apply font-bold;
    @apply mb-5 md:mb-10;
  }

  h3 {
    @apply text-2xl;
    @apply font-bold;
  }

  h4 {
    @apply text-xl;
    @apply font-semibold;
  }

  h5 {
    @apply text-lg;
    /*or text-lg */
    @apply font-semibold;
  }

  h6 {
    @apply text-sm;
    @apply font-semibold;
  }
}

/* .alert {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.alert.fade-out {
  opacity: 0;
} */

.irs-secondary-text-color {
  @apply text-slate-700;
}

.hero-bg-image {
  @apply bg-[100%,auto] xl:bg-[100%,auto] bg-top xl:bg-center;
  @apply bg-fixed;
  background-image: url("/public/images/hero-3.jpg");
  background-repeat: no-repeat;
}

/* CSS */
.irs-button {
  @apply flex items-center justify-center text-center text-white uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  border: 0px;
  box-shadow: 0px 0px 14px -7px #ff3232;
  background-image: linear-gradient(45deg,
      #ce0000 0%,
      #ff3232 51%,
      #ce0000 100%);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.irs-button:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.irs-button:active {
  transform: scale(0.95);
}

.menu li a.active {
  @apply lg:!bg-transparent lg:!text-red-500;
}

::-moz-selection {
  /* Code for Firefox */
  @apply text-white bg-primary;
}

::selection {
  @apply text-white bg-primary;
}

.flip-horizontal {
  transform: scaleX(-1);
  --transform-scale-x: -1;
}

body {
  @apply scrollbar-thin scrollbar-thumb-slate-300 scrollbar-track-slate-100;
}